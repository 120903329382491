import { useSnackbarMessage } from "@hooks/customHooks/useSnackbarMessage";
import { InternalNotificationModel } from "@services/fms-api";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";

type Variables = {
  orderId: number;
  payload: InternalNotificationModel;
};

const sendNotification = async ({
  orderId,
  payload,
}: Variables): Promise<void> => {
  const { data } = await axios.post(
    `orders/${orderId}/internal-notification`,
    payload
  );
  return data;
};

export const useSendInternalNotification = () => {
  const { showSnackbarMessage } = useSnackbarMessage();

  const { mutate, isPending } = useMutation({
    mutationFn: sendNotification,
    onSuccess: () => showSnackbarMessage("Notification sent", "success"),
    onError: () => showSnackbarMessage("Unable to send notification", "error"),
  });

  return {
    sendInternalNotification: mutate,
    isSendingInternalNotification: isPending,
  };
};
