export enum PinType {
  None = 0,
  SpecialInstructions = 1,
  BillingNote = 2,
  PickupNote = 3,
  DeliveryNote = 4,
  CommodityNote = 5,
  BlockerNote = 6,
  ServiceLevelAgreementNote = 7,
}

export const pinTypeOptions: { label: string; value: PinType }[] = [
  { label: "Billing Note", value: PinType.BillingNote },
  { label: "Commodity Note", value: PinType.CommodityNote },
  { label: "Delivery Note", value: PinType.DeliveryNote },
  { label: "General Note", value: PinType.None },
  { label: "Pickup Note", value: PinType.PickupNote },
  { label: "Special Instructions", value: PinType.SpecialInstructions },
  { label: "Blockers", value: PinType.BlockerNote },
  { label: "SLA Note", value: PinType.ServiceLevelAgreementNote },
];
