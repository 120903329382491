import { ClientInboxDto } from "@services/fms-api";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";

type Variables = {
  clientId: number;
  orderedById: number;
  orderedBy2Id: number;
};

const getClientInboxes = async ({
  clientId,
  orderedById,
  orderedBy2Id,
}: Variables): Promise<ClientInboxDto[]> => {
  const { data } = await axios.get(
    `orders/client-inboxes?clientId=${clientId}&orderedById=${orderedById}&orderedBy2Id=${orderedBy2Id}`
  );
  return data;
};

export const useFetchClientInboxes = ({
  clientId,
  orderedById,
  orderedBy2Id,
}: Variables) => {
  const { data, isFetching } = useQuery({
    queryKey: ["clientInboxes", clientId, orderedById, orderedBy2Id],
    queryFn: () => getClientInboxes({ clientId, orderedById, orderedBy2Id }),
    enabled: !!clientId,
    staleTime: 60 * 60 * 1000, // 60 minutes
    gcTime: 65 * 60 * 1000, // 65 minutes
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

  return {
    clientInboxes: data,
    fetchingClientInboxes: isFetching,
  };
};
