import { QueryKey } from "@enums/FE/QueryKey";
import { useSnackbarMessage } from "@hooks/customHooks/useSnackbarMessage";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";

type Variables = {
  orderId: number;
  status: number;
};

const changeStatus = async ({ orderId, status }: Variables): Promise<void> => {
  if (status >= 0) {
    return axios.put(`Orders/${orderId}/change-status?status=${status}`);
  }
};

export const useChangeStatus = () => {
  const queryClient = useQueryClient();
  const { showSnackbarMessage } = useSnackbarMessage();

  const { mutate, isPending } = useMutation({
    mutationFn: changeStatus,
    onSuccess: () =>
      showSnackbarMessage("Order status successfully changed", "success"),
    onError: () =>
      showSnackbarMessage("Unable to change order status", "error"),
    onSettled: (_data, _error, { orderId }) => {
      queryClient.invalidateQueries({
        queryKey: [QueryKey.Order, orderId],
      });
      queryClient.invalidateQueries({ queryKey: [QueryKey.Logs, orderId] });
      queryClient.invalidateQueries({
        queryKey: [QueryKey.Billing, orderId],
      });
    },
  });

  return { changeStatus: mutate, isChangingStatus: isPending };
};
