export const GOOGLE_API_KEY = "AIzaSyCLuYbRbD8R9ILQzFeGAO5bV5jajsIFke8";

export const DEFAULT_ROWS_PER_PAGE = 20;

export const MENU_ITEM_HEIGHT = 48;

export const DEFAULT_CARRIER_REVENUE = 175.01;

export const acceptedFileTypes = {
  "image/*": [".png", ".jpg", ".jpeg"],
  "application/pdf": [".pdf"],
  "application/msword": [".doc"],
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [
    ".docx",
  ],
  "application/vnd.ms-excel": [".xls"],
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
    ".xlsx",
  ],
};

export const centerPoint = {
  lat: 39.9418946,
  lng: -86.24593689999999,
};

export const OD_SIDEBAR_WIDTH = 362;
export const SIDEBAR_WIDTH = 250;

export const PAGE_SIZE = 10;

export const emptyAddressFields = {
  address1: "",
  address2: "",
  city: "",
  state: "",
  zip: "",
};

export const DEFAULT_EMAIL1 = "michelle.bradley@reindeerauto.com";
export const DEFAULT_EMAIL2 = "walt.white@reindeerauto.com";
export const DEFAULT_EMAIL3 = "lauren.glawatz@reindeerauto.com";

export const CLAIM_DEFAULT_EMAIL1 = "marla.rush@reindeerauto.com";
export const CLAIM_DEFAULT_EMAIL2 = "Courtney.Waugh@reindeerauto.com";

export const ADMIN_EMAIL1 = "toni.mccollum@reindeerauto.com";
export const ADMIN_EMAIL2 = "toni.mccollum@reindeerautomotive.onmicrosoft.com";

export const BATCH_DRAWER_WIDTH = 640;

export const CARRIER_INVOICE = "Carrier Invoice";
export const CUSTOMER_INVOICE = "Customer Invoice";

export const GENERAL_FILE_ID = 0;

export const CLAIMS_USER_ID = 8;

export const MAPPING_STEPS = ["Details", "Mapping"];
export const BILLING_MAPPING_STEPS = ["Preview"].concat(MAPPING_STEPS);

export const BLOCKERS_FOR_CANCELLATION_IDS = [15, 16];

export const states = [
  { name: "Alabama", abbreviation: "AL" },
  { name: "Alaska", abbreviation: "AK" },
  { name: "Arizona", abbreviation: "AZ" },
  { name: "Arkansas", abbreviation: "AR" },
  { name: "California", abbreviation: "CA" },
  { name: "Colorado", abbreviation: "CO" },
  { name: "Connecticut", abbreviation: "CT" },
  { name: "Delaware", abbreviation: "DE" },
  { name: "Florida", abbreviation: "FL" },
  { name: "Georgia", abbreviation: "GA" },
  { name: "Hawaii", abbreviation: "HI" },
  { name: "Idaho", abbreviation: "ID" },
  { name: "Illinois", abbreviation: "IL" },
  { name: "Indiana", abbreviation: "IN" },
  { name: "Iowa", abbreviation: "IA" },
  { name: "Kansas", abbreviation: "KS" },
  { name: "Kentucky", abbreviation: "KY" },
  { name: "Louisiana", abbreviation: "LA" },
  { name: "Maine", abbreviation: "ME" },
  { name: "Maryland", abbreviation: "MD" },
  { name: "Massachusetts", abbreviation: "MA" },
  { name: "Michigan", abbreviation: "MI" },
  { name: "Minnesota", abbreviation: "MN" },
  { name: "Mississippi", abbreviation: "MS" },
  { name: "Missouri", abbreviation: "MO" },
  { name: "Montana", abbreviation: "MT" },
  { name: "Nebraska", abbreviation: "NE" },
  { name: "Nevada", abbreviation: "NV" },
  { name: "New Hampshire", abbreviation: "NH" },
  { name: "New Jersey", abbreviation: "NJ" },
  { name: "New Mexico", abbreviation: "NM" },
  { name: "New York", abbreviation: "NY" },
  { name: "North Carolina", abbreviation: "NC" },
  { name: "North Dakota", abbreviation: "ND" },
  { name: "Ohio", abbreviation: "OH" },
  { name: "Oklahoma", abbreviation: "OK" },
  { name: "Oregon", abbreviation: "OR" },
  { name: "Pennsylvania", abbreviation: "PA" },
  { name: "Rhode Island", abbreviation: "RI" },
  { name: "South Carolina", abbreviation: "SC" },
  { name: "South Dakota", abbreviation: "SD" },
  { name: "Tennessee", abbreviation: "TN" },
  { name: "Texas", abbreviation: "TX" },
  { name: "Utah", abbreviation: "UT" },
  { name: "Vermont", abbreviation: "VT" },
  { name: "Virginia", abbreviation: "VA" },
  { name: "Washington", abbreviation: "WA" },
  { name: "West Virginia", abbreviation: "WV" },
  { name: "Wisconsin", abbreviation: "WI" },
  { name: "Wyoming", abbreviation: "WY" },
  { name: "District of Columbia", abbreviation: "DC" },
];

export const USAStates = states.map(({ name, abbreviation }) => ({
  value: abbreviation,
  label: name,
  code: abbreviation,
}));

export const USAStatesShort = states.map(({ abbreviation }) => ({
  value: abbreviation,
  label: abbreviation,
}));
