import FieldTitle from "@components/forms/FieldTitle";
import { FormAutocomplete } from "@components/forms/FormAutocomplete";
import FormDatePickerField from "@components/forms/FormDatePickerField";
import FormInputField from "@components/forms/FormInputField";
import BasicModal from "@components/modals/BasicModal";
import { getDialogStyles } from "@helpers/styles/getDialogStyles";
import { yupResolver } from "@hookform/resolvers/yup";
import { useOrderPermissions } from "@hooks/customHooks/useOrderPermissions";
import { useSnackbarMessage } from "@hooks/customHooks/useSnackbarMessage";
import { useFetchOrder } from "@hooks/orders/useFetchOrder";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Stack from "@mui/material/Stack";
import { ReminderDto, UpsertReminderDto } from "@services/fms-api";
import { useReminderStore } from "@store/useReminderStore";
import { vinRegexp } from "@validation/helpers";
import { reminderSchema } from "@validation/reminderSchema";
import { FC, useCallback, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import OrderNumberAndVINFields from "./OrderNumberAndVINFields";

const getDefaultValues = (reminderToEdit: ReminderDto | null) => ({
  orderId: reminderToEdit?.orderId || null,
  vin: reminderToEdit?.vin || "",
  userId: reminderToEdit?.userId || 0,
  actionDate: reminderToEdit?.actionDate || "",
  note: reminderToEdit?.note || "",
});

type Props = {
  title: string;
  onSubmit: (data: UpsertReminderDto) => void;
  isLoading?: boolean;
};

const ReminderForm: FC<Props> = ({ title, onSubmit, isLoading = false }) => {
  const params = useParams();
  const { showSnackbarMessage } = useSnackbarMessage();

  const { order } = useFetchOrder(Number(params?.orderId || 0));
  const { canEditOrder } = useOrderPermissions();
  const { setOpenReminderModal, setReminderToEdit, reminderToEdit } =
    useReminderStore();

  const defaultValues = getDefaultValues(reminderToEdit);

  const formMethods = useForm<UpsertReminderDto>({
    defaultValues,
    resolver: yupResolver(reminderSchema(!!reminderToEdit)),
  });

  const orderId = formMethods.watch("orderId");
  const vin = formMethods.watch("vin");
  const note = formMethods.watch("note");

  const [inputVin, setInputVin] = useState(vin || "");

  const [applyToOrder, setApplyToOrder] = useState(false);

  const applyValuesToOrder = useCallback(() => {
    if (canEditOrder) {
      if (params?.orderId) {
        formMethods.setValue("orderId", Number(params.orderId));
        order && formMethods.setValue("vin", order?.vehicles?.[0]?.vin || "");
      }
      setApplyToOrder((prev) => !prev);
    }
  }, [params?.orderId, order, formMethods, canEditOrder]);

  useEffect(() => {
    canEditOrder && applyValuesToOrder();
  }, [applyValuesToOrder, canEditOrder]);

  useEffect(() => {
    if (
      Number(orderId) !== Number(params?.orderId) ||
      order?.vehicles?.[0]?.vin !== vin
    ) {
      setApplyToOrder(false);
    }
  }, [order?.vehicles, orderId, params?.orderId, vin]);

  useEffect(() => {
    if (
      canEditOrder &&
      Number(orderId) === Number(params?.orderId) &&
      (order?.vehicles || []).some((vehicle) => vehicle?.vin === vin)
    ) {
      setApplyToOrder(true);
    }
  }, [order?.vehicles, orderId, params?.orderId, vin, canEditOrder]);

  const onError = () => {
    if (!vin) {
      if (inputVin && !vinRegexp.test(inputVin)) {
        formMethods.setError("vin", { message: "Invalid value" });
      } else {
        formMethods.setError("vin", { message: "Required" });
      }
    }
    showSnackbarMessage(
      "Unable to save reminder. Fill required fields to proceed",
      "error"
    );
  };

  const handleCloseDialog = () => {
    setOpenReminderModal(false);
    setReminderToEdit(null);
  };

  const shouldBlock =
    !!Object.values(formMethods.watch()).filter((value) => !!value).length &&
    formMethods.formState.isDirty;

  return (
    <FormProvider {...formMethods}>
      <BasicModal<UpsertReminderDto>
        handleClose={handleCloseDialog}
        title={title}
        handleConfirm={onSubmit}
        handleError={onError}
        isLoadingConfirm={isLoading}
        disabledConfirm={!shouldBlock || !note}
        styles={getDialogStyles("28rem")}
        shouldBlock={shouldBlock}>
        <Stack spacing={1}>
          <FieldTitle title="Order Info" />

          <OrderNumberAndVINFields
            inputVin={inputVin}
            setInputVin={setInputVin}
          />

          {!reminderToEdit && (
            <FormControlLabel
              control={
                <Checkbox
                  checked={applyToOrder}
                  onChange={applyValuesToOrder}
                  disabled={!params?.orderId}
                  disableRipple
                />
              }
              label="Apply to this order"
              sx={{ py: 1 }}
            />
          )}

          <div>
            <FieldTitle title="Reminder Info" />
            <FormAutocomplete
              fieldName="userId"
              label="Reminder For"
              isUsersList
            />
          </div>

          <FormDatePickerField
            fieldName="actionDate"
            fieldText="Action Date"
            disablePast={!reminderToEdit}
          />

          <FormInputField fieldName="note" fieldText="Reminder" lines={5} />
        </Stack>
      </BasicModal>
    </FormProvider>
  );
};

export default ReminderForm;
