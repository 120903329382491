import SendNotificationModal from "@components/modals/SendNotificationModal";
import { ChangeInfoStep } from "@enums/FE/ChangeInfoStep";
import { useCreateReminder } from "@hooks/reminders/useCreateReminder";
import { useUpdateReminder } from "@hooks/reminders/useUpdateReminder";
import { useFetchUsers } from "@hooks/users/useFetchUsers";
import { UpsertReminderDto } from "@services/fms-api";
import { useReminderStore } from "@store/useReminderStore";
import { useState } from "react";
import ReminderForm from "./ReminderForm";

const UpdateReminderFlow = () => {
  const [step, setStep] = useState<ChangeInfoStep>(ChangeInfoStep.Edit);
  const [auditLogId, setAuditLogId] = useState(0);
  const [assigneeEmail, setAssigneeEmail] = useState("");

  const { setOpenReminderModal, reminderToEdit, setReminderToEdit } =
    useReminderStore();

  const { createReminder, isCreatingReminder } = useCreateReminder();
  const { updateReminder, isUpdatingReminder } = useUpdateReminder();
  const { userOptions } = useFetchUsers({ active: true });

  const onSubmit = (data: UpsertReminderDto) => {
    const assigneeEmailAddress =
      userOptions?.find((option) => option.value === data.userId)?.email || "";
    setAssigneeEmail(assigneeEmailAddress);

    !reminderToEdit
      ? createReminder(data, {
          onSuccess: (response) => {
            if (response) {
              setAuditLogId(response);
              setStep(ChangeInfoStep.SendInternalNotification);
            } else {
              setOpenReminderModal(false);
            }
          },
        })
      : updateReminder(
          { ...data, id: reminderToEdit?.id },
          {
            onSuccess: (response) => {
              if (response) {
                setAuditLogId(response);
                setStep(ChangeInfoStep.SendInternalNotification);
              } else {
                setOpenReminderModal(false);
                setReminderToEdit(null);
              }
            },
          }
        );
  };

  return (
    <>
      {step === ChangeInfoStep.Edit ? (
        <ReminderForm
          title={reminderToEdit ? "Edit Reminder" : "Add Reminder"}
          onSubmit={onSubmit}
          isLoading={isCreatingReminder || isUpdatingReminder}
        />
      ) : null}

      {auditLogId && step === ChangeInfoStep.SendInternalNotification ? (
        <SendNotificationModal
          handleSkip={() => {
            setAuditLogId(0);
            setOpenReminderModal(false);
            setReminderToEdit(null);
          }}
          auditLogId={auditLogId}
          initialEmails={assigneeEmail ? [assigneeEmail] : []}
        />
      ) : null}
    </>
  );
};

export default UpdateReminderFlow;
