import moment from "moment";
import { FC, useEffect, useState } from "react";

type TimeBlockProps = {
  label: string;
  value: string;
  withoutBorder?: boolean;
};

const getTime = (zone: string) =>
  moment.tz(moment().format(), zone).format("h:mm a");

const TimeBlock = ({ label, value, withoutBorder = false }: TimeBlockProps) => (
  <div
    style={{
      display: "flex",
      alignItems: "center",
      columnGap: "0.25rem",
      borderLeft: withoutBorder ? undefined : "1px solid rgba(255,255,255,0.6)",
      padding: "0.05rem 0.25rem 0.05rem 0.5rem",
    }}>
    <span
      style={{
        color: "rgba(255,255,255,0.8)",
        fontSize: "0.75rem",
        whiteSpace: "nowrap",
        fontWeight: 600,
      }}>
      {label}
    </span>
    <span
      style={{ fontSize: "0.75rem", whiteSpace: "nowrap", fontWeight: 900 }}>
      {value}
    </span>
  </div>
);

const TimeByZones: FC = () => {
  const [time, setTime] = useState({
    PST: getTime("America/Los_Angeles"),
    MST: getTime("America/Denver"),
    CST: getTime("America/Chicago"),
    EST: getTime("America/New_York"),
  });

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTime({
        PST: getTime("America/Los_Angeles"),
        MST: getTime("America/Denver"),
        CST: getTime("America/Chicago"),
        EST: getTime("America/New_York"),
      });
    }, 10000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        columnGap: "0.25rem",
        padding: "0.15rem",
      }}>
      <TimeBlock label="PST" value={time.PST} withoutBorder />
      <TimeBlock label="MST" value={time.MST} />
      <TimeBlock label="CST" value={time.CST} />
      <TimeBlock label="EST" value={time.EST} />
      <TimeBlock label="Your Current Time" value={moment().format("h:mm a")} />
    </div>
  );
};

export default TimeByZones;
