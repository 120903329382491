import { getStatusListItem, getUserListItem } from "@helpers/getListItem";
import { useFetchUsers } from "@hooks/users/useFetchUsers";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { BasicOption, StatusOption, UserOption } from "@types";
import { FC, RefCallback, forwardRef } from "react";
import {
  Controller,
  FieldValues,
  UseFormClearErrors,
  useFormContext,
} from "react-hook-form";

const renderOption = (
  props: React.HTMLAttributes<HTMLLIElement> & {
    key: any;
  },
  option: BasicOption | UserOption | StatusOption,
  isUsersList?: boolean,
  isStatusesList?: boolean
) =>
  isUsersList ? (
    getUserListItem(props, option as UserOption)
  ) : isStatusesList ? (
    getStatusListItem(props, option as StatusOption)
  ) : (
    <li {...props} key={option.value}>
      {option.label}
    </li>
  );

interface Props {
  fieldName: string;
  label: string;
  options?: (BasicOption | UserOption | StatusOption)[];
  isLoading?: boolean;
  handleChange?: (name?: string, value?: number) => void;
  disabled?: boolean;
  margin?: string;
  disabledOption?: number;
  disableClearable?: boolean;
  triggerFields?: string[];
  isTable?: boolean;
  isRequired?: boolean;
  isUsersList?: boolean;
  isStatusesList?: boolean;
  getOptionDisabled?: (option: BasicOption) => boolean;
}

interface BasicAutocompleteSelectProps
  extends Omit<Props, "onChange" | "value"> {
  value?: string | number;
  onChange: (data?: string | number | null) => void;
  errorMessage?: string;
  selectRef?: RefCallback<HTMLInputElement>;
  clearErrors?: UseFormClearErrors<FieldValues>;
}

const BasicAutocomplete = forwardRef<
  HTMLDivElement,
  BasicAutocompleteSelectProps
>(
  (
    {
      options,
      disabledOption,
      handleChange,
      label,
      isLoading,
      disabled,
      margin,
      value,
      onChange,
      errorMessage,
      selectRef,
      fieldName,
      disableClearable = false,
      clearErrors,
      triggerFields = [],
      isTable = false,
      isRequired = false,
      isUsersList = false,
      isStatusesList = false,
      getOptionDisabled,
    },
    ref
  ) => {
    const { userOptions, isFetchingUsers } = useFetchUsers({
      enabled: isUsersList,
    });

    const dropdownOptions = isUsersList
      ? options ?? userOptions ?? []
      : options ?? [];

    return (
      <Autocomplete
        id={fieldName}
        fullWidth
        disableClearable={disableClearable}
        defaultValue={null}
        options={dropdownOptions}
        value={
          dropdownOptions.find((option) => option?.value === value) ?? null
        }
        isOptionEqualToValue={(option, value) => option?.value === value?.value}
        getOptionLabel={(option) =>
          isUsersList && option.label && (option as UserOption)?.roleName
            ? `${(option as UserOption).roleName} - ${
                option?.label.trim() || (option as UserOption)?.email || ""
              }`
            : option?.label || ""
        }
        getOptionDisabled={(option) =>
          getOptionDisabled?.(option) || option.value === disabledOption
        }
        onInputChange={(_e, value) => {
          if (!value) {
            onChange(null);
            handleChange?.();
          }
        }}
        onChange={(_, data) => {
          onChange(data?.value ?? null);
          handleChange?.(data?.label, data?.value ?? 0);
          if (errorMessage) {
            clearErrors?.([fieldName, ...triggerFields]);
          }
        }}
        renderInput={({ ...params }) => (
          <TextField
            {...params}
            label={label}
            error={!!errorMessage}
            helperText={errorMessage || ""}
            inputRef={selectRef}
            required={isRequired}
          />
        )}
        renderOption={(props, option) =>
          renderOption(props, option, isUsersList, isStatusesList)
        }
        loading={isUsersList ? isFetchingUsers : isLoading}
        filterSelectedOptions
        disabled={disabled}
        sx={{ margin, pb: errorMessage || isTable ? 0 : 3 }}
        ref={ref}
      />
    );
  }
);

BasicAutocomplete.displayName = "BasicAutocompleteComponent";

const FormAutocomplete: FC<Props> = ({ fieldName, ...rest }) => {
  const { control, clearErrors } = useFormContext();

  return (
    <Controller
      control={control}
      name={fieldName}
      render={({ field: { onChange, value, ref }, fieldState: { error } }) => (
        <BasicAutocomplete
          {...rest}
          errorMessage={error?.message}
          value={value}
          onChange={onChange}
          selectRef={ref}
          fieldName={fieldName}
          clearErrors={clearErrors}
        />
      )}
    />
  );
};

export { BasicAutocomplete, FormAutocomplete };
