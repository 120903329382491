import { getUserListItem } from "@helpers/getListItem";
import { useFetchUsers } from "@hooks/users/useFetchUsers";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import Chip from "@mui/material/Chip";
import TextField from "@mui/material/TextField";
import { UserOption } from "@types";
import { emailRegExp } from "@validation/helpers";

type Props = {
  emails: string[];
  setEmails: (value: string[]) => void;
  label?: string;
  margin?: string;
};

const MultipleUsersAutocomplete: React.FC<Props> = ({
  emails,
  setEmails,
  label = "To:",
  margin = "0 0 1rem 0",
}) => {
  const { userOptions, isFetchingUsers } = useFetchUsers();

  const FMSEmails = (userOptions || []).filter((option) =>
    emails.includes(option.email)
  );
  const otherEmails = emails
    .filter(
      (email) => !(userOptions || []).some((option) => option.email === email)
    )
    .map((email) => ({ email, label: "", value: 0 }));

  const handleEmailInput = (
    _e: React.SyntheticEvent<Element, Event>,
    data: (string | UserOption)[]
  ) => {
    const emailValues = data.map((item) =>
      typeof item === "string" ? item : item.email
    );
    setEmails(
      Array.from(new Set(emailValues)).filter(
        (email) => !!emailRegExp.test(email)
      )
    );
  };

  return (
    <Autocomplete
      disableClearable
      freeSolo
      options={userOptions || []}
      multiple
      fullWidth
      isOptionEqualToValue={(option, value) =>
        value?.email ? option.email === value.email : false
      }
      disableListWrap
      value={[...FMSEmails, ...otherEmails]}
      filterSelectedOptions
      filterOptions={createFilterOptions({
        stringify: ({ label, email }) => (label ? `${label} ${email}` : email),
      })}
      renderOption={getUserListItem}
      onChange={handleEmailInput}
      renderInput={(params) => <TextField {...params} label={label} />}
      renderTags={(values, getTagProps) =>
        values.map((option, index) => (
          <Chip
            variant="filled"
            {...getTagProps({ index })}
            key={option?.email}
            label={option?.label?.trim() || option?.email}
          />
        ))
      }
      loading={isFetchingUsers}
      sx={{ margin }}
    />
  );
};

export default MultipleUsersAutocomplete;
