import { useSendInternalNotification } from "@hooks/orders/useSendInternalNotification";
import TextField from "@mui/material/TextField";
import { useActiveOrderStore } from "@store/useActiveOrderStore";
import { FC, useState } from "react";
import UsersToNotify from "../UsersToNotify";
import BasicModal from "./BasicModal";

type Props = {
  handleSkip: () => void;
  auditLogId: number;
  initialEmails?: string[];
};

const SendNotificationModal: FC<Props> = ({
  handleSkip,
  auditLogId,
  initialEmails = [],
}) => {
  const { order } = useActiveOrderStore();

  const [emails, setEmails] = useState<string[]>(initialEmails);
  const [comment, setComment] = useState("");

  const { sendInternalNotification, isSendingInternalNotification } =
    useSendInternalNotification();

  const handleSend = () => {
    sendInternalNotification(
      {
        orderId: order?.id || 0,
        payload: { emails, comment, auditLogId },
      },
      { onSettled: handleSkip }
    );
  };

  return (
    <BasicModal
      handleClose={handleSkip}
      title="Would you like to send any internal notifications?"
      handleConfirm={handleSend}
      confirmBtnText="Send"
      cancelBtnText="Skip"
      isLoadingConfirm={isSendingInternalNotification}
      disabledConfirm={!emails.length}
      maxWidth="md">
      <>
        <UsersToNotify emails={emails} setEmails={setEmails} />

        <TextField
          label="Comment"
          fullWidth
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          multiline
          rows={5}
        />
      </>
    </BasicModal>
  );
};

export default SendNotificationModal;
