import { useFetchOrder } from "@hooks/orders/useFetchOrder";
import { useSearchByOrderId } from "@hooks/orders/useSearchByOrderId";
import { useSearchByVin } from "@hooks/orders/useSearchByVin";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { UpsertReminderDto } from "@services/fms-api";
import { vinRegexp } from "@validation/helpers";
import { useEffect, useState } from "react";
import { Controller, useFormContext, useWatch } from "react-hook-form";

type Props = {
  inputVin: string;
  setInputVin: React.Dispatch<React.SetStateAction<string>>;
  isEditing?: boolean;
};

const OrderNumberAndVINFields: React.FC<Props> = ({
  inputVin,
  setInputVin,
  isEditing,
}) => {
  const { control, clearErrors, setValue, setError, formState } =
    useFormContext<UpsertReminderDto>();
  const { orderId, vin } = useWatch({ control });

  const [inputOrderId, setInputOrderId] = useState("");
  const [vinOptions, setVinOptions] = useState<string[]>([]);
  const [ordersOptions, setOrdersOptions] = useState<string[]>([]);

  const { ordersByOrderId, fetchingOrdersByOrderId } = useSearchByOrderId(
    inputOrderId || ""
  );
  const { ordersByVin, fetchingOrdersByVin } = useSearchByVin(inputVin || "");
  const { order: orderDetails } = useFetchOrder(Number(orderId) || 0);

  useEffect(() => {
    if (!vin && ordersByOrderId) {
      setOrdersOptions(
        ordersByOrderId?.map((order) => order?.toString() || "")
      );
    }
  }, [ordersByOrderId, vin]);

  useEffect(() => {
    if (ordersByVin && !orderId) {
      setOrdersOptions(
        ordersByVin.map((order) => order?.orderId?.toString() || "")
      );
    }
  }, [vin, ordersByVin, orderId]);

  useEffect(() => {
    if (orderDetails) {
      setVinOptions(
        (orderDetails?.vehicles || []).map((vehicle) => vehicle?.vin || "")
      );
    }
  }, [orderDetails]);

  return (
    <>
      <Controller
        name="orderId"
        control={control}
        render={({
          field: { onChange, value, name },
          fieldState: { error },
        }) => {
          return (
            <Autocomplete
              id={name}
              value={value?.toString() || ""}
              onChange={(_event, newValue) => {
                onChange(newValue);
                clearErrors("orderId");
                if (!newValue) {
                  setInputOrderId("");
                  setValue("orderId", null);
                  setVinOptions([]);
                  setOrdersOptions([]);
                  setError("orderId", { message: "Required" });
                }
              }}
              inputValue={inputOrderId}
              onInputChange={(_event, newInputValue) => {
                setInputOrderId(newInputValue);
              }}
              options={ordersOptions}
              fullWidth
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    label="Order Number"
                    sx={{ margin: "0.5rem 0 1rem 0" }}
                    error={!!error}
                    helperText={error?.message}
                  />
                );
              }}
              loading={
                !!((vin || inputVin) && fetchingOrdersByVin) ||
                !!((orderId || inputOrderId) && fetchingOrdersByOrderId)
              }
              disabled={isEditing}
            />
          );
        }}
      />

      {!vinOptions.length ? (
        <TextField
          label="VIN"
          value={inputVin}
          fullWidth
          onChange={(e) => {
            const value = e.target.value;
            setInputVin(value);
            if (vinRegexp.test(value)) {
              setValue("vin", value);
              clearErrors("vin");
            } else {
              setError("vin", { message: "Invalid value" });
            }
            if (!value) {
              setInputVin("");
              setValue("vin", "");
              setOrdersOptions([]);
              setError("vin", { message: "Required" });
            } else {
              clearErrors("vin");
            }
          }}
          error={!!formState.errors?.vin}
          helperText={
            formState.errors?.vin && (formState.errors?.vin?.message as string)
          }
        />
      ) : (
        <Controller
          name="vin"
          control={control}
          render={({
            field: { onChange, value, name },
            fieldState: { error },
          }) => {
            return (
              <Autocomplete
                id={name}
                value={value}
                onChange={(_event, newValue) => {
                  onChange(newValue);
                  clearErrors("vin");
                  if (!newValue) {
                    setInputVin("");
                    setOrdersOptions([]);
                    setError("vin", { message: "Required" });
                  }
                }}
                inputValue={inputVin}
                onInputChange={(_event, newInputValue) => {
                  setInputVin(newInputValue);
                }}
                options={vinOptions}
                fullWidth
                renderInput={(params) => {
                  return (
                    <TextField
                      {...params}
                      label="VIN"
                      error={!!error}
                      helperText={error?.message}
                    />
                  );
                }}
                loading={fetchingOrdersByVin}
              />
            );
          }}
        />
      )}
    </>
  );
};

export default OrderNumberAndVINFields;
