import Chip from "@mui/material/Chip";
import { StatusOption, UserOption } from "../types";

export const getUserListItem = (
  props: React.HTMLAttributes<HTMLLIElement>,
  userOption: UserOption
) => (
  <li {...props} key={userOption.value} style={{ display: "block" }}>
    <div
      style={{
        display: "flex",
        columnGap: "0.5rem",
        alignItems: "center",
      }}>
      {userOption?.roleName ? (
        <Chip label={userOption.roleName} size="small" />
      ) : null}
      <p style={{ flex: 1 }}>{userOption?.label || ""}</p>
    </div>

    <p style={{ fontSize: "0.875rem" }}>{userOption.email}</p>
  </li>
);

export const getStatusListItem = (
  props: React.HTMLAttributes<HTMLLIElement>,
  statusOption: StatusOption
) => (
  <li {...props} key={statusOption.value} style={{ display: "block" }}>
    <div
      style={{
        display: "flex",
        columnGap: "0.5rem",
        alignItems: "center",
      }}>
      {statusOption?.color ? (
        <div
          style={{
            width: "1rem",
            height: "1rem",
            backgroundColor: statusOption?.color,
            borderRadius: "50%",
          }}
        />
      ) : null}
      <p style={{ flex: 1 }}>{statusOption?.label || ""}</p>
    </div>
  </li>
);
