import { QueryKey } from "@enums/FE/QueryKey";
import { useSnackbarMessage } from "@hooks/customHooks/useSnackbarMessage";
import { UpsertReminderDto } from "@services/fms-api";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";

const updateReminder = async (payload: UpsertReminderDto): Promise<number> => {
  const { data } = await axios.put("reminders", payload);
  return data;
};

export const useUpdateReminder = () => {
  const queryClient = useQueryClient();
  const { showSnackbarMessage } = useSnackbarMessage();

  const { mutate, isPending } = useMutation({
    mutationFn: updateReminder,
    onSuccess: () => showSnackbarMessage("Reminder updated", "success"),
    onError: () => showSnackbarMessage("Unable to update reminder", "error"),
    onSettled: (_data, _error, { orderId }) => {
      queryClient.invalidateQueries({ queryKey: [QueryKey.Reminders] });
      queryClient.invalidateQueries({
        queryKey: [QueryKey.Order, QueryKey.Reminders, orderId],
      });
    },
  });

  return { updateReminder: mutate, isUpdatingReminder: isPending };
};
