import { PinType } from "@enums/BE/PinType";
import { QueryKey } from "@enums/FE/QueryKey";
import { useSnackbarMessage } from "@hooks/customHooks/useSnackbarMessage";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { NoteDto } from "@types";
import axios from "axios";

const createNote = ({
  text,
  subject,
  orderId,
  attachments,
  emails,
  pinType,
  includeInCustomerReport,
  serviceLevelAgreementFields,
  orderBlockerId,
}: NoteDto): Promise<void> => {
  const formData = new FormData();
  formData.append("text", text);
  subject && formData.append("subject", subject);
  formData.append("orderId", orderId.toString());
  attachments?.forEach((attachment) =>
    formData.append("attachments", attachment as File)
  );
  emails && emails.forEach((email) => formData.append("emails", email));
  formData.append("pinType", (pinType ?? PinType.None).toString());
  pinType === PinType.BlockerNote &&
    formData.append(
      "includeInCustomerReport",
      (includeInCustomerReport || false).toString()
    );
  orderBlockerId &&
    formData.append("orderBlockerId", orderBlockerId.toString());

  if (
    serviceLevelAgreementFields &&
    pinType === PinType.ServiceLevelAgreementNote
  ) {
    serviceLevelAgreementFields?.serviceLevelAgreementRationaleId &&
      formData.append(
        "ServiceLevelAgreementFields.ServiceLevelAgreementRationaleId",
        serviceLevelAgreementFields.serviceLevelAgreementRationaleId.toString()
      );
    serviceLevelAgreementFields?.serviceLevelAgreementRationaleOptionId &&
      formData.append(
        "ServiceLevelAgreementFields.ServiceLevelAgreementRationaleOptionId",
        serviceLevelAgreementFields.serviceLevelAgreementRationaleOptionId.toString()
      );
    serviceLevelAgreementFields?.revisedDate &&
      formData.append(
        "ServiceLevelAgreementFields.RevisedDate",
        serviceLevelAgreementFields.revisedDate
      );
    formData.append(
      "ServiceLevelAgreementFields.RequestException",
      (serviceLevelAgreementFields.requestException || false).toString()
    );
    formData.append(
      "ServiceLevelAgreementFields.RequestRevisedServiceLevelAgreement",
      (
        serviceLevelAgreementFields.requestRevisedServiceLevelAgreement || false
      ).toString()
    );
    formData.append(
      "ServiceLevelAgreementFields.CreateReminder",
      (serviceLevelAgreementFields.createReminder || false).toString()
    );
    serviceLevelAgreementFields?.reminderForUserId &&
      formData.append(
        "ServiceLevelAgreementFields.ReminderForUserId",
        serviceLevelAgreementFields.reminderForUserId.toString()
      );

    serviceLevelAgreementFields?.reviewServiceLevelAgreementRequests
      ?.clientExceptionRequestResponse &&
      formData.append(
        "ServiceLevelAgreementFields.ReviewServiceLevelAgreementRequests.ClientExceptionRequestResponse",
        serviceLevelAgreementFields.reviewServiceLevelAgreementRequests.clientExceptionRequestResponse.toString()
      );

    serviceLevelAgreementFields?.reviewServiceLevelAgreementRequests
      ?.revisedServiceLevelAgreementResponse &&
      formData.append(
        "ServiceLevelAgreementFields.ReviewServiceLevelAgreementRequests.RevisedServiceLevelAgreementResponse",
        serviceLevelAgreementFields.reviewServiceLevelAgreementRequests.revisedServiceLevelAgreementResponse.toString()
      );
  }

  return axios.post("notes", formData);
};

export const useCreateOrderNote = () => {
  const queryClient = useQueryClient();
  const { showSnackbarMessage } = useSnackbarMessage();

  const { mutate, isPending } = useMutation({
    mutationFn: createNote,
    onSuccess: () => showSnackbarMessage("Note successfully saved", "success"),
    onError: () => showSnackbarMessage("Unable to save note", "error"),
    onSettled: (_data, _error, { orderId }) => {
      queryClient.invalidateQueries({ queryKey: [QueryKey.Notes, orderId] });
      queryClient.invalidateQueries({ queryKey: [QueryKey.Order, orderId] });
    },
  });

  return { createOrderNote: mutate, isCreatingOrderNote: isPending };
};
