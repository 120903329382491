import FormHelperText from "@mui/material/FormHelperText";
import { useController, useFormContext } from "react-hook-form";
import ReactQuill from "react-quill-new";
import "react-quill-new/dist/quill.snow.css";

const modules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }],
    [{ size: [] }],
    ["bold", "italic", "underline", "strike"],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ color: [] }, { background: [] }],
    [{ align: [] }],
    ["clean"],
  ],
};

type Props = {
  markup: string;
  placeholder: string;
  error?: string;
  readOnly?: boolean;
  onChange: (value: string) => void;
};

const RichTextEditor: React.FC<Props> = ({
  markup,
  placeholder,
  error,
  readOnly = false,
  onChange,
}) => {
  const editorStyle = {
    border: error ? "1px solid red" : undefined,
    borderRadius: "0.25rem",
  };

  return (
    <>
      <ReactQuill
        value={markup}
        onChange={(
          value: string,
          _delta: any,
          _source: any,
          editor: ReactQuill.UnprivilegedEditor
        ) => {
          const hasContent = editor.getText().trim().length > 0;
          if (hasContent) {
            onChange(value);
          } else {
            onChange("");
          }
        }}
        theme="snow"
        modules={modules}
        placeholder={placeholder}
        readOnly={readOnly}
        style={editorStyle}
      />
      {error ? (
        <FormHelperText sx={{ color: "error.main", ml: 1.5 }}>
          {error}
        </FormHelperText>
      ) : null}
    </>
  );
};

type ControlledProps = {
  fieldName: string;
  markup: string;
  placeholder: string;
  readOnly?: boolean;
};

const ControlledRichTextEditor: React.FC<ControlledProps> = ({
  fieldName,
  markup,
  placeholder,
  readOnly,
}) => {
  const { control, clearErrors } = useFormContext();
  const {
    field: { value, onChange },
    fieldState: { error },
  } = useController({ defaultValue: markup, name: fieldName, control });

  return (
    <RichTextEditor
      markup={value}
      onChange={(value) => {
        clearErrors(fieldName);
        onChange(value);
      }}
      error={error?.message || ""}
      placeholder={placeholder}
      readOnly={readOnly}
    />
  );
};

export { ControlledRichTextEditor, RichTextEditor };
