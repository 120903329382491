import FieldTitle from "@components/forms/FieldTitle";
import MultipleUsersAutocomplete from "@components/forms/MultipleUsersAutocomplete";
import { ADMIN_EMAIL1, ADMIN_EMAIL2 } from "@constants";
import { getUserName } from "@helpers/getUserName";
import { useFetchClientInboxes } from "@hooks/orders/useFetchClientInboxes";
import { useFetchUsers } from "@hooks/users/useFetchUsers";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { ClientInboxDto, ShortUserDto } from "@services/fms-api";
import { useActiveOrderStore } from "@store/useActiveOrderStore";

type Props = {
  emails: string[];
  setEmails: (emails: string[]) => void;
};

const UsersToNotify: React.FC<Props> = ({ emails, setEmails }) => {
  const { order } = useActiveOrderStore();

  const { userOptions } = useFetchUsers();
  const { clientInboxes } = useFetchClientInboxes({
    clientId: order?.clientInfo?.rpsClientId || 0,
    orderedById: order?.orderedBy?.rpsClientId || 0,
    orderedBy2Id: order?.orderedBy2?.rpsClientId || 0,
  });

  const adminUser = (userOptions || []).find(
    (user) => user.email === ADMIN_EMAIL1 || user.email === ADMIN_EMAIL2
  );

  const handleCheckboxSelect = (assignee?: ShortUserDto | ClientInboxDto) => {
    emails?.some((email) => email === assignee?.email)
      ? setEmails(emails?.filter((email) => email !== assignee?.email))
      : setEmails((emails || [])?.concat(assignee?.email || ""));
  };

  return (
    <>
      <FieldTitle title="Assignees" />
      <div style={{ display: "flex", columnGap: "0.5rem", flexWrap: "wrap" }}>
        {(order?.orderAssignees || []).map((assignee) => (
          <FormControlLabel
            key={assignee?.id}
            control={
              <Checkbox
                checked={emails?.some(
                  (email) => email === assignee.user?.email
                )}
                onChange={() => handleCheckboxSelect(assignee.user)}
              />
            }
            label={
              <p>
                <span
                  style={{ fontStyle: "italic", textDecoration: "underline" }}>
                  {assignee.role?.name}:
                </span>{" "}
                <span>{getUserName(assignee.user)}</span>
              </p>
            }
            sx={{ width: "30%", wordBreak: "break-word", flexShrink: 0 }}
          />
        ))}

        <FormControlLabel
          control={
            <Checkbox
              checked={emails?.some((email) => email === adminUser?.email)}
              onChange={() => handleCheckboxSelect(adminUser)}
            />
          }
          label={
            <p>
              <span
                style={{ fontStyle: "italic", textDecoration: "underline" }}>
                Admin:
              </span>{" "}
              <span>{getUserName(adminUser)}</span>
            </p>
          }
          sx={{ width: "30%", wordBreak: "break-word", flexShrink: 0 }}
        />
      </div>
      <div style={{ marginTop: "1.5rem" }}>
        <FieldTitle title="Client Inbox" />

        <div
          style={{
            display: "flex",
            columnGap: "0.5rem",
            flexWrap: "wrap",
            marginBottom: "0.75rem",
          }}>
          {clientInboxes
            ? clientInboxes.map((clientInbox, idx) => (
                <FormControlLabel
                  key={clientInbox?.email || idx}
                  control={
                    <Checkbox
                      checked={emails?.some(
                        (email) => email === clientInbox.email
                      )}
                      onChange={() => handleCheckboxSelect(clientInbox)}
                    />
                  }
                  label={clientInbox?.email || ""}
                  sx={{
                    width: "45%",
                    wordBreak: "break-word",
                    flexShrink: 0,
                  }}
                />
              ))
            : null}
        </div>
      </div>

      <MultipleUsersAutocomplete emails={emails || []} setEmails={setEmails} />
    </>
  );
};

export default UsersToNotify;
