import { PinType } from "@enums/BE/PinType";
import * as yup from "yup";
import {
  numberIntegerValidation,
  stringRequiredValidation,
  stringValidation,
} from "./helpers";

const SLANoteFieldsSchema = yup.object().shape({
  serviceLevelAgreementRationaleId: numberIntegerValidation
    .moreThan(0, "Required")
    .required("Required"),
  serviceLevelAgreementRationaleOptionId: yup.number().nullable(),
  revisedDate: yup
    .string()
    .nullable()
    .test("is-required", "Required", function (value) {
      return this.parent.requestRevisedServiceLevelAgreement ? !!value : true;
    }),
  requestException: yup.boolean(),
  requestRevisedServiceLevelAgreement: yup.boolean(),
  createReminder: yup
    .boolean()
    .test("is-checked", "Required", function (value) {
      return (this.parent?.requestRevisedServiceLevelAgreement &&
        !this.parent?.reviewServiceLevelAgreementRequests
          ?.revisedServiceLevelAgreementResponse) ||
        (this.parent?.requestException &&
          !this.parent?.reviewServiceLevelAgreementRequests
            ?.clientExceptionRequestResponse)
        ? value === true
        : true;
    }),
  reminderForUserId: yup
    .number()
    .nullable()
    .test("is-required", "Required", function (value) {
      return this.parent.createReminder ? !!value : true;
    }),
});

export const editNoteSchema = yup.object().shape({
  orderId: numberIntegerValidation.moreThan(0, "Required").required("Required"),
  subject: stringValidation.nullable(),
  emails: yup.array().of(yup.string().email()).nonNullable(),
  text: stringRequiredValidation,
  attachments: yup.array().of(yup.mixed()),
  pinType: yup
    .mixed<PinType>()
    .oneOf(Object.values(PinType) as PinType[])
    .required("Required"),
  includeInCustomerReport: yup.boolean(),
});

export const addNoteSchema = editNoteSchema.concat(
  yup.object().shape({
    serviceLevelAgreementFields: yup
      .mixed()
      .when("pinType", (pinType, schema) =>
        (pinType?.[0] as PinType) === PinType.ServiceLevelAgreementNote
          ? SLANoteFieldsSchema
          : schema.notRequired()
      ),
  })
);
